import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import { ro, en, fr, hu, nl, pt, it, de, tr, es } from './translations';

i18next.init({
  interpolation: { escapeValue: false, prefix: '{', suffix: '}' }, // React already does escaping
  lng: window.location.host.match(/\.education/) ? 'en' : 'ro', // language to use
  react: {
    // ...
    hashTransKey: function (defaultValue) {
      // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
    },
    defaultTransParent: 'div', // a valid react element - required before react 16
    transEmptyNodeValue: '', // what to return for empty Trans
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    transWrapTextNodes: '', // Wrap text nodes in a user-specified element.
    // i.e. set it to 'span'. By default, text nodes are not wrapped.
    // Can be used to work around a well-known Google Translate issue with React apps. See: https://github.com/facebook/react/issues/11538
    // (v11.10.0)
  },
  resources: {
    ro: { common: ro },
    en: { common: en },
    fr: { common: fr },
    hu: { common: hu },
    nl: { common: nl },
    pt: { common: pt },
    it: { common: it },
    de: { common: de },
    tr: { common: tr },
    es: { common: es },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
