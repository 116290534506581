import React, { useEffect } from 'react';
import square_dots_BG from '../../assets/subscriptions/square_dots_BG.svg';
import checkmark from '../../assets/subscriptions/checkmark_icon.svg';
// import arrow_down from '../../assets/subscriptions/arrowdown_expand.svg';
// import { useNavigate } from 'react-router-dom';
import Api2 from '../../../src/Libs/api';
import { useState } from 'react';
// import useCheckHostname from '../../utility/helper';

const ParentCards = () => {
  // const packagePrice = useCheckHostname('education') ? 3.99 : 1.99;
  // const currency = '€';
  // const navigate = useNavigate();

  const [parentSubscriptions, setParentSubscriptions] = useState();

  const getTranslation = (subscription, field, language = 'ro', fallback) => {
    return subscription[field].find((l) => l.langId === language)?.value || fallback;
  };

  const getLangfromUrl = () => {
    let urlData = window.location.pathname.slice(1, 3);
    if (urlData === 'ro') {
      return urlData;
    }
    return 'en';
  };

  useEffect(() => {
    let parentSubs = [];

    const fetchParentSubscriptions = async () => {
      Api2.get(`/subscriptions/packages/available?countryId=1&subscriberType=student`).then(
        (data) => {
          const sortedData = data.data.sort((a, b) => a.order - b.order);

          setParentSubscriptions(sortedData);
          parentSubs = sortedData;
        }
      );
    };
    +parentSubs?.length === 0 && fetchParentSubscriptions();
  }, []);

  return (
    <div className="packages__cards">
      {parentSubscriptions?.map((subscription, index) => {
        const lang = getLangfromUrl();
        const featuresDescription = getTranslation(subscription, 'featuresDescription', lang);
        const subscriptionName = getTranslation(subscription, 'packageName', lang);
        const subscriptionDescription = getTranslation(subscription, 'packageDescription', lang);

        return (
          <div
            className={`packages__card`}
            style={{ '--theme-color': `var(--${subscription.colorTheme}-500)` }}
            key={index}
          >
            {index === 0 && (
              <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
            )}

            {subscriptionName && <h3>{subscriptionName}</h3>}
            {subscriptionDescription && <p className="txt-center">{subscriptionDescription}</p>}

            <br />

            {featuresDescription && <h5>{featuresDescription}</h5>}

            <div className="packages__card__benefits">
              {Array.from({ length: 5 }, (_, i) => i + 1).map((i, index) => {
                const benefit = getTranslation(subscription, `feature${i}`, lang);

                return benefit ? (
                  <div className="packages__card__benefit" key={index}>
                    <img src={checkmark} alt="checkmark" />
                    <p>{benefit}</p>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        );
      })}
      {/* <div className={`packages__card packages__card--light--green parents__card--1`}>
        <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
        <h3> {t('packages.parent.0.name')}</h3>
        <p className="txt-center"> {t('packages.parent.0.type')}</p>
        <h4>
          <span>
            {currency}
            {packagePrice}
          </span>
          <br />
          <div className="vat-included--2">
            <p>{t('packages.vatIncluded')}</p>
          </div>
          {t('packages.parent.0.billingType')}
        </h4> 
        <br />
        <h5> {t('packages.parent.0.benefitsTitle')}</h5>
        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.parent.0.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.parent.0.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>
        {/* <div className="packages__card__bottom">
          <div className="packages__card__bottom__gradient"></div>
          <div className="packages__card__separator packages__card__bottom__separator"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard1Open(true)}
          />
          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=parent&package=0`)
            }
          >
            {t('packages.parent.0.btn')}
          </button>
        </div> 
      </div> */}
    </div>
  );
};

export default ParentCards;
